import { useRouter } from "solito/router";

import type { IconProps } from "@tamagui/helpers-icon";
import {
  ChevronRight,
  HelpCircle as HelpCircleIcon,
  ReceiptText as ReceiptTextIcon,
  Search as SearchIcon,
} from "@tamagui/lucide-icons";

import { IssueType } from "@medbillai/graphql-types";

import {
  Circle,
  Paragraph,
  type ParagraphProps,
  type StackProps,
  XStack,
  YStack,
} from "@medbillai/ui";

import { issuePreviewUrl } from "@medbillai/utils/urls";

import { analyticsService } from "../../../lib/analytics";
import { WidgetHeader } from "./WidgetHeader";

const billUrl = issuePreviewUrl(IssueType.UNKNOWN, "I need help with a bill.");
const coverageUrl = issuePreviewUrl(
  IssueType.UNKNOWN,
  "I have a question about what is covered by my health insurance.",
);
const questionUrl = issuePreviewUrl(IssueType.ONBOARDING);

export const MessageWidget = () => {
  const { push } = useRouter();

  const handlePress = (type: "BILL" | "COVERAGE" | "QUESTION", url: string) => {
    analyticsService.trackEvent("ClickedHomeScreenChatEventButton", {
      type,
    });
    push(url);
  };

  return (
    <YStack
      paddingBottom="$6"
      paddingTop="$4"
      borderBottomWidth={0.5}
      borderBottomColor="$strokeSecondary"
    >
      <WidgetHeader label="Get Started" />
      <YStack gap="$2.5" paddingHorizontal="$4" pt="$4">
        <MessageSuggestion
          icon={ReceiptTextIcon}
          iconProps={{
            color: "$background",
          }}
          paragraphProps={{
            color: "$background",
          }}
          circleProps={{
            borderColor: "$navy5",
            backgroundColor: "$navy",
          }}
          stackProps={{
            backgroundColor: "$navy",
          }}
          chevronProps={{
            color: "$background",
          }}
          title="Help with a bill"
          onPress={() => handlePress("BILL", billUrl)}
        />
        <MessageSuggestion
          icon={SearchIcon}
          stackProps={{
            backgroundColor: "$cream",
          }}
          title="Check my coverage"
          onPress={() => handlePress("COVERAGE", coverageUrl)}
        />
        <MessageSuggestion
          icon={HelpCircleIcon}
          stackProps={{
            backgroundColor: "$background",
          }}
          title="Ask a question"
          onPress={() => handlePress("QUESTION", questionUrl)}
        />
      </YStack>
    </YStack>
  );
};

interface MessageSuggestionProps {
  icon: React.NamedExoticComponent<IconProps>;
  iconProps?: IconProps;
  circleProps?: StackProps;
  paragraphProps?: ParagraphProps;
  stackProps?: StackProps;
  chevronProps?: IconProps;
  title: string;
  onPress: () => void;
}

const MessageSuggestion = ({
  icon: Icon,
  iconProps,
  paragraphProps,
  circleProps,
  stackProps,
  chevronProps,
  title,
  onPress,
}: MessageSuggestionProps) => {
  return (
    <XStack
      br="$10"
      height="$6"
      px="$4"
      alignItems="center"
      justifyContent="space-between"
      borderWidth={0.5}
      borderColor="$strokeSecondary"
      onPress={onPress}
      pressStyle={{
        scale: 0.98,
      }}
      animation="200ms"
      {...stackProps}
    >
      <XStack alignItems="center" gap="$4">
        <Circle
          size={34}
          borderWidth={0.5}
          borderColor="$strokeSecondary"
          backgroundColor="$cream"
          {...circleProps}
        >
          <Icon size={18} color="$color" {...iconProps} />
        </Circle>
        <Paragraph bold {...paragraphProps}>
          {title}
        </Paragraph>
      </XStack>
      <ChevronRight size="$1" color="$color" {...chevronProps} />
    </XStack>
  );
};
